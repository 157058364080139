.header {
	--floating: 1;
	//height: 0;
	color: inherit;
	left: 0;
	position: absolute;
	top: 0;
	width: 100%;
	z-index: 4;
  	pointer-events: none;

	&--static,
	&--staticNoToggler {
		--floating: 0;
	}

	&:not(&--floating) {
		@include media($mediumBreakpoint) {
			--floating: 0;
		}
	}

	&.js-coveredByMenu {
		color: $colorInverted;
	}

	&__content {
		@include app-container();
		@include ease(background-color transform, $duration3);
		align-items: center;
		background-color: transparent;
		display: flex;
		flex-direction: row;
		height: $headerHeight;
		justify-content: space-between;
		padding-top: $headerTopPadding;
		pointer-events: none;
		position: relative;

		.header:not(.js-coveredByMenu):not(.js-searchActive).js-collapsed & {
			transform: translateY(-100%);
		}

		&::before {
			@include ease(opacity, $duration4);
			content: '';
			height: 100%;
			left: 0;
			opacity: 0.5;
			position: absolute;
			top: 0;
			width: 100%;
			z-index: 0;

			.header.js-searchActive & {
				opacity: 1;
			}

			@each $schemeName, $value in $colorBgHeaderOverContent {
				$selectors: '.body--colorScheme' +
					ucFirst($schemeName) +
					' .header:not(.js-coveredByMenu).js-overContent &, ' +
					'.body--colorScheme' +
					ucFirst($schemeName) +
					' .header.js-searchActive &';

				#{$selectors} {
					background-color: $value;
				}
			}
		}
	}

	&__homeLink {
		color: currentColor;
		display: flex;
		flex-direction: column;
		height: 100%;
		justify-content: center;
		pointer-events: auto;

		@include media($columnsBreakpoint) {
			margin-top: $base4;
		}
	}

	&__logo {
	}

	&__menu {
		@include ease(opacity visibility, $duration4);
		@include font(header);
		align-items: center;
		display: flex;
		justify-content: flex-end;

		.header.js-searchActive & {
			@include fadeOut();
		}

		@include media('>medium'){
			display: none;
		}
	}

	&__menuItem {
		pointer-events: auto;

		&:not(:first-child) {
			margin-left: $base2;

			@include media($mediumBreakpoint) {
				margin-left: $base3;
			}
		}

		&--menuToggler {
			line-height: 0;

			.header--static & {
				@include media($mediumBreakpoint) {
					visibility: hidden;
				}
			}

			.header--static.js-coveredByMenu & {
				@include media($mediumBreakpoint) {
					visibility: inherit;
				}
			}
		}

		&--regionsToggler {
			@include ease(opacity visibility, $duration4);

			.header.js-coveredByMenu & {
				@include fadeOut();
			}
		}
	}

	&__search {
		@include ease(right width padding-right, $duration4);
		align-items: stretch;
		display: flex;
		height: 100%;
		padding: 0 $base2;
		pointer-events: auto;
		position: absolute;
		right: $base * 11;
		top: 0;
		width: calc(100% - #{$base * 10 + $base7 + $base4});

		@include media($mediumBreakpoint) {
			@include ease(right width, $duration6);
			right: $base * 14;
			width: $base * 16;
		}

		.header.js-coveredByMenu & {
			display: none;
		}

		.header.js-searchActive & {
			padding-right: $base6;
			right: 0;
			width: 100%;
		}
	}

	&__title {
		@include ease(opacity visibility, $duration4);
		height: 100%;
		pointer-events: auto;

		.header.js-searchActive & {
			@include fadeOut();
		}
	}
}
