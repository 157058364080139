
@mixin app-neutralizeContentPadding() {
	$entries: map-get($blockPaddingValues, horizontal);

	@each $option, $optionValues in $entries {
		@if map-get($optionValues, value) {
			$value: map-get($optionValues, value);
			width: calc(100% + #{$value * 2});
		}
		@if map-get($optionValues, variants) {
			@each $media, $mediaValues in map-get($optionValues, variants) {
				$value: map-get($mediaValues, value);
				@include media($media) {
					width: calc(100% + #{$value * 2});
				}
			}
		}
	}
}

