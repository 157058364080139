
@mixin app-blockHorizontalSize($baseClass: '.block', $nestedSelector: ' > .block__container > &') {
	@each $option, $value in $maxWidthValues {
		$selector: $baseClass + '--hSize' + ucFirst($option) + $nestedSelector;

		#{$selector} {
			max-width: $value;
		}
	}
}
