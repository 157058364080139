.mainMenu {
	@include fadeOut($duration4);
	background-color: $colorBgInverted;
	color: $colorInverted;
	display: none;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	left: 0;
	padding-top: ($headerHeight + $base5);
	position: relative;
	right: 0;
	top: 0;
	z-index: 3;

	@include media('>medium') {
		background-color: transparent;
		display: block;
		opacity: 1;
		visibility: inherit;
		z-index: 3;
		padding: 0;
		position: absolute;
		top: $base5;
	}

	&.js-fixed {
		position: fixed;
	}

	&.js-beforeActive {
		display: block;
		will-change: opacity, visibility;
	}

	&.js-duringActive {
		@include fadeIn();
	}

	&::before {
		background-color: transparentize($colorBgInverted, 0.2);
		bottom: 0;
		content: '';
		left: 0;
		position: fixed;
		right: 0;
		top: 0;
		z-index: 0;

		@include media('>small') {
			display: none;
		}
	}

	&__content {
		@include app-container($onlyPadding: true);
		// padding-bottom: $headerHeight;
		position: relative;
		z-index: 2;

		@include media('>small') {
			@include app-container();
		}
	}

	&__items{
		display: flex;
		flex-direction: column;
	}
	&__item{
		font-weight: bold;
		margin-bottom: $base1;
		& a:-webkit-any-link{
			text-decoration: none;
			color: $colorBgBase;
		}
		&--special{
			& a:-webkit-any-link{
				display: flex;
				width: fit-content;
				gap: $base1;
				align-items: center;
				background: $colorBgBase;
				color: $colorFocus;
				border-radius: $base*4.5;
				padding: $baseHalf $base*1.2;
			}
		}
	}
	@include media('>medium') {
		&__items{
			flex-direction: row;
			gap: $base*3.2;
			justify-content: flex-end;
			align-items: center;
		}
		&__item{
			margin-bottom: 0;
			& a:-webkit-any-link{
				color: $colorBase;
			}
			&--special{
				& a:-webkit-any-link{	
					color: $colorFocus;
				}
			}
		}
	}

	&__arrow{
		& svg{
			color: transparent;
		}
	}
	
}
