
@mixin app-blockFixedMinHeight($baseClass: '&', $blockClass: '.block', $nestedSelector: '::before') {
	@each $name, $value in $fixedMinHeightValues {
		$modifier: $baseClass + '--vSizeFixed' + $blockClass + '--vSizeFixed' + ucFirst($name);
		$multiModifier: $baseClass + '--vSizeMultiFixed' + $blockClass + '--vSizeFixedMulti' + ucFirst($name);
		$selector: $modifier + $nestedSelector;
		$multiSelector: $multiModifier + $nestedSelector;
		$exclusionSelector: $modifier + ':not(' + $baseClass + '--vSizeMultiFixed)' + $nestedSelector;

		#{$selector} {
			padding-bottom: $value;
		}

		#{$exclusionSelector} {
			@include media($columnsBreakpoint) {
				padding-bottom: 0;
			}
		}

		#{$multiSelector} {
			@include media($columnsBreakpoint) {
				padding-bottom: $value;
			}
		}
	}
}
