.logo {
	display: inline-block;
	height: auto;
	line-height: 0;
	padding: 0;
	width: $logoWidth;

	svg {
		height: auto;
		width: 100%;
	}
}
