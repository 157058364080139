.figure {
	max-width: 100%;

	&--listLink {
		flex-grow: 1;
	}

	&--bg {
		display: block;
		height: 100%;
		overflow: hidden;
		user-select: none;
		width: 100%;

		.block--vSizeBg > .block__bg & {
			flex-grow: 1;
			height: auto;

			@include media($columnsBreakpoint) {
				height: 100%;
			}
		}

		.block--vSizeMultiBg > .block__bg & {
			@include media($columnsBreakpoint) {
				flex-grow: 1;
				height: auto;
			}
		}

		.block__bg--video & {
			@include ease(opacity visibility, $duration5);
		}

		.block__bg--video.js-started & {
			@include fadeOut();
		}
	}

	.block--twoContents &--content {
		grid-area: figure;
	}

	// slx specific
	&--altBg {
		@include ease(opacity visibility, $duration4);
		@include fadeOut();
		backface-visibility: hidden;
		height: 100%;
		left: 0;
		position: absolute;
		top: 0;
		width: 100%;
		z-index: 2;

		@include onHover('.listTile--withAltBg', ' &') {
			@include fadeIn();
		}
	}

	&__caption {
		@include font(caption);
		margin-top: $base;

		// .blocks:not(.blocks--nested) > .block--image.block--hSizeFull & {
		// 	@include app-container();
		// }

		.figure--bg & {
			bottom: 0;
			left: 0;
			padding: $base;
			position: absolute;
			text-align: right;
			width: 100%;
		}
	}
}
