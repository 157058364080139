
@mixin app-blockBorders($baseClass: '&', $nestedSelector: '') {
	$dirs: (left, right, top, bottom);

	@each $dir in $dirs {
		//border_top_multi
		$modifier: $baseClass + '--' + 'border' + ucFirst($dir);
		$multiModifier: $modifier + 'Multi';
		$selector: $modifier + $nestedSelector;
		$multiSelector: $multiModifier + $nestedSelector;
		$exclusionSelector: $modifier + ':not(' + $multiModifier + ')' + $nestedSelector;
		$property: 'border-' + $dir;

		#{$selector} {
			#{$property}: $blockBorderSize solid $colorBorder;
		}

		#{$exclusionSelector} {
			@include media($columnsBreakpoint) {
				#{$property}: 0;
			}
		}

		#{$multiSelector} {
			@include media($columnsBreakpoint) {
				#{$property}: $blockBorderSize solid $colorBorder;
			}
		}
	}
}
