.block {
	@include app-blockBorders();
	@include app-colorSchemes();
	@include app-blockFixedMinHeight();
	@include app-container();
	// display: grid; // due to problems with displaying on small screens
	flex-grow: 1;
	position: relative;


	&:first-of-type {
		padding-top: $base10;
	}

	&:first-of-type.block--full {
		padding-top: 0;
	}

	&--full {
		width: 100%;
		padding-top: 0;
		padding-left: 0;
		padding-right: 0;
		max-width: none;

	}



	&--hAlignBegin {
		text-align: left;
	}

	&--hAlignEnd {
		text-align: right;
	}

	&--hAlignCenter {
		text-align: center;
	}

	&--hAlignJustified {
		text-align: justify;
	}

	@include media($columnsBreakpoint) {
		flex-grow: var(--inColumnGrowFactor, 1);
	}

	.layout__innerGridItem > & {
		flex-grow: 1;

		@include media($columnsBreakpoint) {
			flex-grow: 1;
		}
	}

	&--breadcrumbs.block--breadcrumbsModeFloating {
		pointer-events: none;
	}

	&--slide {
		grid-column: 1 / 2;
		grid-row: 1 / 2;

		.slideshow--animationTypeFade & {
			@include ease(opacity visibility, $slideshowFadeDuration);
			@include fadeOut();
			z-index: 1;

			&.js-current {
				@include fadeIn();
				z-index: 2;
			}
		}

		.slideshow--animationTypeSlide .slideshow__slides.js-animate & {
			@include ease(transform, $slideshowSlideDuration);
		}

		.slideshow--animationTypeSlide.slideshow--circular & {
			transform: translateX(calc(100% * var(--slideBalancedPosition)));
		}

		.slideshow--animationTypeSlide:not(.slideshow--circular) & {
			transform: translateX(calc(100% * var(--slidePosition)));
		}
	}

	&--submenu {
		&.block--colorSchemeInverted {
			background-color: $colorBgSubmenu;
		}
	}

	// used to set min height with ratios and vh size
	&::before {
		content: '';
		display: block;
		grid-column: 1 / 2;
		grid-row: 1 / 2;
		width: 100%;
		// padding-bottom value set by a mixin in the block
	}

	&__container {
		//@include app-blockPadding();
		color: inherit;
		display: flex;
		flex-direction: column;
		flex-grow: 0;
		flex-shrink: 0;
		grid-column: 1 / 2;
		grid-row: 1 / 2;
		order: 2;
		padding: 0; //$blockVerticalPaddingBaseSmall $blockHorizontalPaddingBaseSmall;
		position: relative;
		text-decoration: none;
		width: 100%;
		z-index: 1;

		.block--hPositionBegin > & {
			align-items: flex-start;
		}

		.block--hPositionCenter > & {
			align-items: center;
		}

		.block--hPositionEnd > & {
			align-items: flex-end;
		}

		.block--vAlignBegin > &,
		.block--vAlignGapBeforeContent > &,
		.block--vAlignGapAfterContent > &,
		.block--vAlignGapAroundContent > & {
			justify-content: flex-start;
		}

		.block--vAlignEnd > & {
			justify-content: flex-end;
		}

		.block--vAlignCenter > & {
			justify-content: center;
		}

		.block--productDownloadWizard & {
			overflow: hidden;
			position: relative;

			.body.js-productDownloadWizardModal & {
				overflow: visible;
			}
		}
	}

	&__content {
		// @include app-container();
		@include app-blockHorizontalSize();
		display: flex;
		flex-direction: column;
		order: 4;
		position: relative;
		width: 100%;
		z-index: 1;

		.block--hSizeFullNoContentPadding > .block__container > & {
			@include app-neutralizeContentPadding();
		}

		.block--vAlignGapBeforeContent > .block__container > & {
			flex-grow: 1;
			justify-content: flex-end;
		}

		.block--vAlignGapAfterContent > .block__container > & {
			flex-grow: 1;
		}

		.block--vAlignGapAroundContent > .block__container > & {
			flex-grow: 1;
		}

		* + & {
			margin-top: $base * 2.4;

			// @include media($mediumBreakpoint) {
			// 	margin-top: $base * 4.8;
			// }
		}

		.block--slideshow.block--vSizeFixed > .block__container > & {
			flex-grow: 1;

			@include media($columnsBreakpoint) {
				flex-grow: 0;
			}
		}

		.block--slideshow.block--vSizeMultiFixed > .block__container > & {
			@include media($columnsBreakpoint) {
				flex-grow: 1;
			}
		}

		.block--productModels & {
			margin-top: 0;

			@include media($mediumBreakpoint) {
				margin-top: 0;
			}
		}

		.block--twoContents & {
			display: grid;
			grid-template-areas:
				'figure'
				'text';
			grid-template-columns: 100%;
			grid-template-rows: auto auto;

			@include media($columnsBreakpoint) {
				grid-template-areas: 'figure	text';
				grid-template-columns: 1fr 1fr;
				grid-template-rows: auto;
			}
		}

		.block--twoContents.block--twoContentsOrderInverted & {
			grid-template-areas:
				'text'
				'figure';
		}

		.block--twoContents.block--twoContentsOrderMultiInverted & {
			@include media($columnsBreakpoint) {
				grid-template-areas: 'text		figure';
			}
		}

		$map: map-get($twoContentsGaps, vertical);
		@each $name, $values in $map {
			$selector: '.block--twoContents.block--twoContentsVerticalGap' + ucFirst($name) + ' &';

			#{$selector} {
				@include mediaMap($map, $name, row-gap);
			}
		}

		$map: map-get($twoContentsGaps, horizontal);
		@each $name, $values in $map {
			$selector: '.block--twoContents.block--twoContentsHorizontalGap' + ucFirst($name) + ' &';

			#{$selector} {
				@include mediaMap($map, $name, column-gap);
			}
		}
	}

	&__bg {
		display: flex;
		flex-direction: column;
		flex-grow: 0;
		flex-shrink: 0;
		grid-column: 1 / 2;
		grid-row: 1 / 2;
		height: auto;
		left: 0;
		order: 1;
		top: 0;
		width: 100%;
		z-index: 0;

		&--video {
			overflow: hidden;
		}

		.block--vSizeAuto > &,
		.block--vSizeFixed > & {
			height: 100%;
			position: absolute;

			@include media($columnsBreakpoint) {
				height: auto;
				position: relative;
			}
		}

		.block--vSizeMultiAuto > &,
		.block--vSizeMultiFixed > & {
			@include media($columnsBreakpoint) {
				height: 100%;
				position: absolute;
			}
		}
	}

	&__header {
		@include app-blockHorizontalSize();
		display: flex;
		flex-direction: column;
		width: 100%;
		z-index: 1;

		.block--vAlignGapAroundContent > .block__container > & {
			flex-grow: 1;
		}
	}

	&--principles{
		margin-top: $base10;

		background: $colorBgAlternative;
		outline-width: 100vw;
		outline-color: $colorBgAlternative;
		outline-style: solid;
		clip-path: inset(0 -100vw 0 -100vw);
	}
	&--benefits{
		background: $colorBgAlternative;
		outline-width: 100vw;
		outline-color: $colorBgAlternative;
		outline-style: solid;
		clip-path: inset(0 -100vw 0 -100vw);
	}
}
